import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router'
import {apiUrl, timeToRefreshToken, minLoginTime, startLogintime} from "@/variables"
import VueJwtDecode from 'vue-jwt-decode'
import countries from "i18n-iso-countries";

// register en and de as default
countries.registerLocale(require(`i18n-iso-countries/langs/en.json`));
countries.registerLocale(require(`i18n-iso-countries/langs/de.json`));

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        status: '',
        loginError: '',
        registerError: '',
        token: localStorage.getItem('token') || '',
        userRoles: [],
        countries: countries,
        supportedLangs: [],
        isoCountryList: [],
        loginTime: localStorage.getItem('loginTime') ? parseInt(localStorage.getItem('loginTime')) : null,
        timerInterval: null,
        loginRefreshToken: sessionStorage.getItem('loginRefreshToken') || '',
        timeToRefreshToken: timeToRefreshToken,
        startLogintime: startLogintime,
        minLoginTime: minLoginTime,
    },
    mutations: {
        setLoginTime(state, value) {
            state.loginTime = value;
            localStorage.setItem('loginTime', value);
        },
        decrementLoginTime(state) {
            if (state.loginTime > 0) {
                state.loginTime -= 1;
                localStorage.setItem('loginTime', state.loginTime);
            } else {
                clearInterval(state.timerInterval);
                state.timerInterval = null;
            }
        },
        auth_success(state, token) {
            state.token = token
        },
        auth_error(state, error) {
            state.status = 'error'
            state.loginError = error
        },
        logout(state) {
            state.status = ''
            state.token = ''
            state.userRoles = []
            clearInterval(state.timerInterval);
            state.timerInterval = null;
            localStorage.removeItem('loginTime');
        },
        commitCurrentUserRoles(state, userRoles) {
            state.userRoles = userRoles
        },
        commitRegisterCountries(state, isoCountryList) {
            state.isoCountryList = isoCountryList
        },
        SET_ACTIVE(state) {
            if (state.token) {
                state.loginTime = state.startLogintime;
                localStorage.setItem('loginTime', state.startLogintime);
            }
        }
    },
    actions: {
        startLoginTimeDown({commit, state, dispatch}) {
            if (!state.timerInterval) {
                state.timerInterval = setInterval(() => {
                    commit('decrementLoginTime');
                    if (state.loginTime <= 0) {
                        dispatch('logout');
                    }
                }, 1000);
            }
        },
        refreshToken({commit, dispatch, state}) {
            if (state.token) {
                const headers = {Authorization: 'Bearer ' + localStorage.getItem("token") || null}
                const loginRefreshToken = sessionStorage.getItem('loginRefreshToken');
                return axios.post(apiUrl + '/api/token/refresh', {refresh_token: loginRefreshToken}, {headers})
                    .then(response => {
                        let newToken = response?.data?.token;
                        if (newToken) {
                            localStorage.setItem('token', newToken);
                            commit('auth_success', newToken);
                            commit('auth_error', "");
                        }
                    })
                    .catch(() => {
                        dispatch('logout');
                    });
            }
        },
        registerCountries({commit}) {
            // register all alpha2 codes
            let alpha2 = Object.keys(countries.getAlpha2Codes())

            // collection of supported countries with available english name
            let isoCountryList = []

            // fill isoCountryList with supported countries
            for (let it of alpha2) {
                let country = {
                    code: it.toLowerCase(),
                    deName: countries.getName(it.toUpperCase(), 'de'),
                    enName: countries.getName(it.toUpperCase(), 'en')
                }

                // if no enName found - country is not supported (enName is needed for user country saving)
                if (!country.enName) {
                    continue
                }

                // if no deName found - use enName as fallback (=> only eventually needed for future update compatibility)
                if (!country.deName) {
                    country.deName = country.enName
                }

                // add country to isoCountryList
                isoCountryList = [...isoCountryList, country]
                isoCountryList.sort((a, b) => (a.enName > b.enName) ? 1 : ((b.enName > a.enName) ? -1 : 0))
            }
            commit('commitRegisterCountries', isoCountryList)
        },
        setCurrentUserRoles({commit}) {
            const token = localStorage.getItem("token")
            const user = VueJwtDecode.decode(token)
            if (user && user.roles) {
                commit('commitCurrentUserRoles', user.roles)
            }
        },
        authStatus({dispatch, state}) {
            const headers = {Authorization: 'Bearer ' + localStorage.getItem("token") || null}

            axios.post(apiUrl + `/api/auth`, '', {
                headers: headers
            })
            .then((response) => {
                if (response?.data?.remainingValidity && response?.data?.remainingValidity < state.timeToRefreshToken) {
                    dispatch('refreshToken');
                }
            })
            .catch(() => {
                dispatch('logout');
                router.push({'path': '/'})
            })
        },
        login({commit, state, dispatch}, payload) {
            return new Promise((resolve) => {

                const headers = {
                    'Content-Type': 'application/json'
                }

                axios.post(apiUrl + `/api/login`, payload, {
                    headers: headers
                }).then(response => {
                    if (response.code === 401) {
                        commit('auth_error', 'XXX')
                        resolve(response)
                    } else {
                        const token = response.data.token;
                        const loginRefreshToken = response.data.refresh_token;
                        const decodedToken = VueJwtDecode.decode(token)
                        localStorage.setItem('securedAPIKey', decodedToken.securedAPIKey);
                        localStorage.setItem("token", token)
                        sessionStorage.setItem('loginRefreshToken', loginRefreshToken)
                        state.loginTime = state.startLogintime;
                        commit('auth_success', token)
                        commit('auth_error', "")
                        dispatch('startLoginTimeDown');
                        resolve(response)
                        router.push('/dashboard')
                    }
                }).catch(error => {
                    commit('auth_error', error.response.data.message)
                })
            })
        },
        logout({commit}) {
            return new Promise((resolve) => {
                commit('logout');
                localStorage.removeItem('token')
                localStorage.removeItem('securedAPIKey')
                sessionStorage.removeItem('loginRefreshToken')
                router.push({path: '/'})
                resolve();
            })
        },
        userClicked({commit, dispatch}) {
            commit('SET_ACTIVE');
            dispatch('startLoginTimeDown');
        }
    },
    getters: {
        isLoggedIn: state => !!state.token,
        minLoginTime: state => state.minLoginTime,
        loginError: state => state.loginError,
        registerError: state => state.registerError,
        authStatus: state => state.status,
        countDown: state => state.loginTime,
        formattedLoginTime: state => {
            const minutes = Math.floor(state.loginTime / state.startLogintime);
            const seconds = state.loginTime % state.startLogintime;
            return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        }
    }
})
