var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('language-flags',{staticClass:"position-absolute end-0 mr-3 mt-3"}),(_vm.shouldShowDialog)?_c('Dialog',{attrs:{"show":_vm.shouldShowDialog},on:{"close":_vm.handleDialogClose}}):_vm._e(),[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"col-12 col-sm-3"},[_c('v-img',{staticStyle:{"left":"-0.5rem"},attrs:{"height":"100","max-height":"100","max-width":"400","min-height":"100","src":"/cemo-logo.svg"}})],1),_c('b-col',{staticClass:"col-12 col-sm-9",attrs:{"align":"right","align-self":"center"}},[(_vm.$store.state.userRoles.canDownloadProductImages || _vm.$store.state.userRoles.canDownloadProductDocuments)?[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"large":""}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.$i18n.t('mediadb.home.button.multi_download')))]),_c('v-icon',[_vm._v("mdi-information-outline")])],1)]}}],null,false,2945518214),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" "+_vm._s(_vm.$i18n.t('mediadb.home.text.multi_download_title'))+" ")]),_c('v-card-text',[_c('b-row',[_c('b-col',{staticClass:"col-12 pt-10 pb-0"},[_c('v-alert',{attrs:{"outlined":"","text":"","type":"info"}},[_vm._v(_vm._s(_vm.$i18n.t('mediadb.home.text.multi_download_description'))+" ")])],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('div',[_c('b-col',{staticClass:"col-12 pt-0 pb-4"},[_c('v-btn',{staticClass:"mr-2",attrs:{"large":""},on:{"click":_vm.handleFileImport}},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t('mediadb.home.button.csv_upload')))]),_c('v-icon',[_vm._v("mdi-upload")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"large":""},on:{"click":_vm.handleDemoCsvDownload}},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t('mediadb.home.button.csv_demo')))]),_c('v-icon',[_vm._v("mdi-download")])],1),_c('v-file-input',{ref:"uploader",staticClass:"d-none",attrs:{"accept":"text/csv","prepend-icon":"mdi-upload"},on:{"change":_vm.onFileChanged},model:{value:(_vm.selectedFile),callback:function ($$v) {_vm.selectedFile=$$v},expression:"selectedFile"}})],1)],1)],1)],1)],1)]:_vm._e(),_c('v-btn',{staticClass:"ml-5",attrs:{"large":""},on:{"click":_vm.logout}},[_c('span',[_vm._v(_vm._s(_vm.$i18n.t('mediadb.home.button.sign_out')))]),_c('v-icon',[_vm._v("mdi-logout")])],1)],2)],1),_c('ais-instant-search',{attrs:{"index-name":_vm.liveIndexName,"search-client":_vm.searchClient}},[_c('b-row',[_c('b-col',{staticClass:"py-5",attrs:{"cols":"12"}},[_c('ais-search-box',{attrs:{"class-names":{
                  'ais-SearchBox': 'text-center',
                  'ais-SearchBox-input': 'w-50',
                },"placeholder":("" + (_vm.$i18n.t('mediadb.home.input.search_article')))}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ais-hits',{scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('b-card',{staticClass:"mb-2 border-0",staticStyle:{"max-width":"20rem"},attrs:{"title":item.description,"tag":"article"}},[(item && item.images && Object.keys(item.images).length && Object.keys(item.images).length >= 1 && item.images[0])?_c('v-img',{attrs:{"src":_vm.liveUrl + item.images[0].thumbnail}}):_c('v-img',{attrs:{"src":"/no_image_found.jpg"}}),_c('b-card-text',{staticClass:"text-center pt-10"},[_c('strong',[_vm._v(_vm._s(_vm.$i18n.t('mediadb.home.text.order_no')))]),_vm._v(" "+_vm._s(item.orderno)+" ")]),_c('images-lightbox',{attrs:{"documents":item.documents,"images":item.images,"name":item.description}}),_c('v-btn',{staticClass:"mt-2",attrs:{"block":"","color":"#ff9800","dark":"","target":"_blank","href":_vm.liveUrl + '/pdf/single/' + item.orderno + '?lang=' + _vm.$i18n.locale}},[_vm._v(_vm._s(_vm.$i18n.t('mediadb.data_sheet.product')))])],1)]}}])})],1)],1),_c('ais-pagination',{staticClass:"text-center pt-12",attrs:{"padding":3,"show-first":true,"show-last":true,"show-next":true,"show-previous":true}})],1)],1)],_c('v-snackbar',{attrs:{"value":_vm.snackbarCsvErrorValue,"color":"error","text":"","timeout":"5000","top":""},model:{value:(_vm.snackbarCsvError),callback:function ($$v) {_vm.snackbarCsvError=$$v},expression:"snackbarCsvError"}},[_vm._v(" "+_vm._s(_vm.snackbarCsvErrorValue)+". ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }