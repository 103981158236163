<template>
  <div class="text-center pa-4">
    <v-dialog
        v-model="dialog"
        width="auto"
        @input="handleDialogInput"
    >
      <v-card
          max-width="650"
      >
        <v-card-title style="display: block" class="headline">
          <p> {{ $i18n.t('mediadb.home.dialog.text.expired.warning') }} {{ $store.getters.formattedLoginTime }}
            {{ $i18n.t('mediadb.home.dialog.text.expired.timetype') }}</p>
        </v-card-title>
        <v-card-text>
          {{ $i18n.t('mediadb.home.dialog.text.expired.stayloggedin') }}
        </v-card-text>
        <template v-slot:actions>
          <v-btn
              class="ms-auto"
              text="Ok"
              @click="closeDialog"
          ></v-btn>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: this.show,
      loginTime: localStorage.getItem('loginTime'),
    };
  },
  watch: {
    show(newVal) {
      this.dialog = newVal;
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.$emit('close');
    },
    handleDialogInput(val) {
      this.dialog = val;
    },
  },
};
</script>

<style scoped>
.text-center {
  text-align: center;
}
</style>
